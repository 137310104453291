/*
Template Name: Doot - Responsive Bootstrap 5 Chat App
Author: Themesbrand
Version: 1.0.0
Website: https://Themesbrand.com/
Contact: Themesbrand@gmail.com
File: Main Css File
*/

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";
@import "variables";
@import "theme-light";
@import "theme-dark";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/accordion";
@import "custom/components/modals";
@import "custom/components/forms";
@import "custom/components/modalFeedbackTransaction";
@import "custom/components/ModalLoadingTransferMetaMask.scss";
@import "custom/components/sideMenuMobile";
@import "custom/components/pageNotFound404";
@import "custom/components/tooltip";
@import "custom/components/homeMenuMobile";
@import "custom/components/cardAction";
@import "custom/components/sideMenuType2";
@import "custom/components/mainLayout";
@import "custom/components/chatMain";
@import "custom/components/inputMainChat";
@import "custom/components/attachmentFiles";
@import "custom/components/menuTopBar";
@import "custom/components/groupLogin";
@import "custom/components/account";
@import "custom/components/skeletonMessage";
@import "custom/components/cardAssistantsNewLayout";
@import "custom/components/buttonDefaultAccount";
@import "custom/components/modalInfoAssistants";

// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/emoji-picker";
@import "./markdown";
@import "animate.css";

// structure
@import "custom/structure/general";
@import "custom/structure/sidemenu";

// Pages
@import "custom/pages/chat-leftsidebar";
@import "custom/pages/user-chat";
@import "custom/pages/user-profile-details";
@import "custom/pages/authentication";
@import "custom/pages/brand-assets";
@import "custom/pages/newLayout";
@import "custom/pages/assistants";
@import "custom/pages/settings";
@import "custom/pages/landing-page";

body {
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -webkit-optimize-contrast: antialiased !important;
}

img {
  image-rendering: -webkit-optimize-contrast !important;
}

.one-line {
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.two-line {
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.three-line {
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.card {
  --bs-card-border-radius: 16px;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.font-extrabold {
  font-weight: 800;
}

.font-medium {
  font-weight: 500;
}

.supabase-auth-ui_ui-message {
  background-color: #f4e877;
  width: 100%;
  padding: 14px;
  border: 1px solid #131223;
  border-radius: 4px;
  color: #131223;
}

.file-uploader-drop {
  position: absolute;
  width: 100%;
  height: 100%;
  .dropzone {
    padding: 0 !important;
    margin: 0 !important;
    height: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 8px !important;
    cursor: pointer;
    border: none !important;

    &:hover {
      img,
      span {
        color: $gray700 !important;
      }
    }
    svg {
      display: none !important;
    }

    > div,
    input {
      display: none !important;
    }
  }
}

.guZdik {
  border: none !important;
  width: 100px !important;
  max-width: 100px !important;
}

p:last-child {
  margin-bottom: 0;
}

// body[data-layout-mode="dark"] {
//   .c-dumjqv[href="#auth-sign-up"] {
//     color: #fff;
//   }
// }

.c-dumjqv[href="#auth-sign-up"] {
  font-weight: 600 !important;
}

.slide-in-right-300-enter {
  transform: translateX(100%);
}

.slide-in-right-300-enter-active {
  transform: translateX(0);
  transition: all 0.3s;
}

.slide-in-right-300-exit {
  transform: translateX(0);
}

.slide-in-right-300-exit-active {
  transform: translateX(100%);
  transition: all 0.3s;
}

.starter-page-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem;
  background: none;
  border-radius: 8px;
  transition: 0.5s;
  color: #8b90aa;
  border: 1px solid #8b90aa;

  @media screen and (max-width: 767px) {
    flex-direction: row;
    padding: 0.5rem;
  }

  &:hover {
    color: #212034;
    border: 1px solid #212034;
  }

  i {
    color: $primary;
  }

  .bx-help-circle {
    color: #8b90aa;
  }
}

// body[data-layout-mode="dark"] {
//   .starter-page-button {
//     border: 1px solid #343247;
//     color: #fff;

//     &:hover {
//       color: #fff;
//       border: 1px solid #fff;
//     }

//     .bx-help-circle {
//       color: #8b90aa;
//     }
//   }

//   .accordion {
//     &:nth-child(1) {
//       border-top: 1px solid black;
//     }

//     .card {
//       border: none;
//       border-bottom: 1px solid black;
//       background-color: transparent !important;
//       border-radius: 0;
//       margin: 0;

//       .card-header {
//         background-color: transparent !important;
//         border: none;
//         display: flex;
//         align-items: center;
//         padding: 15px 0;

//         button {
//           background: none;
//           color: #000 !important;
//           border: none;
//           font-size: 1.1rem;
//           font-weight: 700;
//           padding: 0;
//         }
//       }

//       .card-body {
//         color: #000;
//         padding: 0 0 15px 0;
//       }
//     }
//   }

//   .border-green {
//     border: 1px solid #3bcdbc !important;
//   }

//   .color-green-buttons {
//     color: #3bcdbc !important;
//   }

//   .c-egTDuJ-iwjZXY-color-default {
//     color: #212034 !important;
//     font-weight: 450;
//   }

//   .c-egTDuJ-cmFMMs-color-primary {
//     color: #fff !important;
//     font-weight: 450;
//   }

//   .color-white-buttons {
//     color: #fff !important;
//   }
//   .tab-links {
//     color: #0f0f1c !important;
//   }

//   button,
//   a {
//     color: #adb5bd !important;
//   }
// }

.accordion {
  &:nth-child(1) {
    border-top: 1px solid black;
  }

  .card {
    border: none;
    border-bottom: 1px solid black;
    background-color: transparent !important;
    border-radius: 0;
    margin: 0;

    .card-header {
      background-color: transparent !important;
      border: none;
      display: flex;
      align-items: center;
      padding: 15px 0;

      button {
        background: none;
        color: #000 !important;
        border: none;
        font-size: 1.1rem;
        font-weight: 700;
        padding: 0;
      }
    }

    .card-body {
      color: #000;
      padding: 0 0 15px 0;
    }
  }
}

.image-skeleton {
  background-color: #f2f2f2;
  width: 100%;
  overflow: hidden;
  max-height: 400px;
  max-width: 450px;
  min-width: 250px;
  min-height: 250px;
  border-radius: 8px;
  opacity: 1;
  animation: skeletonFade 1.5s ease-in-out infinite;
}

.chat-input-image {
  @media (max-width: 993px) {
    margin-bottom: 3rem;
  }
}

.button-image {
  all: unset;
}

.amplified-image {
  max-width: 100%;
}

.modal-button-image {
  border-radius: 8px;
  padding: 12px;

  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    filter: saturate(1.5);
  }
}

.input-image {
  min-height: 140px !important;
}

.credit-card {
  border: 1px solid transparent;
  transition: all 0.15s;
}

.credit-card:hover {
  border: 1px solid $primary;
  transform: translateY(-8px);
}

.modal-select-avatar {
  .button-select-assistant-image {
    width: 100px !important;
    all: unset;
    border: 1.5px solid transparent;
    padding: 4px;
    transition: all 0.25s;

    &:hover {
      border: 1.5px solid white;
      transform: translateY(-6px);
      filter: brightness(1.05) contrast(1.05) saturate(1.05);
    }

    img {
      cursor: pointer;
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
  }
}

.button-go-premium {
  all: unset;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: $premium;
  color: white;
  transition: filter 0.2s;

  &:hover {
    filter: saturate(1.5);
  }
}

.grid-images {
  column-count: 5;
  column-gap: 0.5rem;
  margin-top: 2rem;
}

.image-ia {
  img {
    max-width: 100%;
    object-fit: cover;
    filter: brightness(1) contrast(1) saturate(1);
    transition: filter 0.3s ease;
    border-radius: 4px !important;

    &:hover {
      filter: brightness(1.1) contrast(1.1) saturate(1.1);
    }
  }
}

.modal-image .modal-content {
  border: none !important;
}

@media (max-width: 1024px) {
  .grid-images {
    column-count: 3;
  }
}

@media (max-width: 500px) {
  .grid-images {
    column-count: 2;
  }

  .header-image-page {
    h3 {
      margin-top: -1rem;
      font-size: 1.15rem;
    }

    p {
      height: 0;
      visibility: hidden;
    }
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 4px;
}

::-webkit-scrollbar:horizontal {
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba($dark, 0.4);
  border-radius: 10px;
  border: 2px solid transparent;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

@media screen and (max-width: 767px) {
  .plans-column {
    display: flex;
    flex-direction: column-reverse;
  }
}

.modal-title-plan {
  h5.modal-title {
    width: 100%;
    font-size: 22px !important;
  }
}

.modalPlan .table > :not(caption) > * > * {
  padding: 0.25rem 0.5rem;
}

.ais-Hits-item {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
}
.ais-Hits-list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  list-style: none;

  div {
    min-height: 44px;
  }
}

.ais-SearchBox-form {
  display: flex;
  align-items: center;
  background: $gray100;
  border-radius: 12px;
  padding: 0 16px;
  margin-bottom: 24px;
}

.ais-SearchBox-input {
  width: 100%;
  flex: 1;
  font-size: 14px;
  color: #42434e;
  border: 0;
  outline: none;
  order: 1;
  padding: 0 0 0 12px;
  &::placeholder {
    content: "Search..." !important;
  }

  @media (max-width: 576px) {
    min-width: auto;
  }
}

.ais-SearchBox-submit {
  order: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  fill: $gray900;
  pointer-events: none;

  svg {
    width: 16px !important;
    height: 16px !important;
  }
}

.ais-SearchBox-reset {
  display: none;
}

.page-assistants-algolia {
  .ais-Hits-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
  }
  .ais-SearchBox-form {
    gap: 12px;
  }
  .ais-Hits-list div {
    min-height: auto;
  }
}

// body[data-layout-mode="dark"] .ais-SearchBox-input {
//   background-color: #0f0f1c !important;
//   color: #797c8c !important;
// }

// body[data-layout-mode="dark"] .ais-SearchBox-submit {
//   fill: #000 !important;
// }

.css-szj68g {
  background: #13af9d !important;
  color: #131223 !important;
}

.c-egTDuJ-cmFMMs-color-primary {
  color: #0f0f1c !important;
}

.premium-message {
  font-weight: 600;
  text-align: start;
  line-height: 1.6;
}

// Landing page

.btn-lp-dark {
  background: $color-logo-dark;
  color: #fff !important;
}

.btn-lp-ligth {
  background: $color-logo-light;
}

.font-home {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.size-title-landing-page {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  line-height: 1.1;
  font-size: 3rem;
  font-weight: 500;
  color: black !important;

  @media (max-width: 991.98px) {
    font-size: 2.5rem;
  }

  @media (max-width: 767.98px) {
    font-size: 2rem;
  }
}

.size-paragrapy-landing-page {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 1.125rem;
  line-height: 1.6;
  color: black !important;

  @media (max-width: 767.98px) {
    font-size: 1rem;
  }
}

// avatar

.radius-avatar {
  border-radius: 10px 10px 0px 10px;
}

.view_assistants {
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 5px;
    background: red;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
}

// border
.button-border-lg {
  border-radius: 14px 14px 3px 14px !important;
  i {
    color: #fff;
  }
}
.button-border-sm {
  border-radius: 8px 8px 2px 8px !important;
  i {
    color: #fff;
  }
}

.border-active {
  h6 {
    padding-bottom: 2px;
    border-bottom: 1px solid black !important;
  }
}

.border-right {
  content: "";
  position: absolute;
  top: 0;
  left: calc(100% - 40px);
  border-right: 1px solid #00000032;
  height: 100%;
  @media (max-width: 576px) {
    display: none;
  }
}

.button-small {
  min-width: 36px;
  max-width: 36px;
  min-height: 36px;
  max-height: 36px;
  i {
    font-size: 19px !important;
  }
}

.custom-swiper {
  overflow: visible !important;
  width: 900px;
}

// btn-create-image
.btn-create-image {
  display: flex;
  @media (max-width: 450px) {
    flex-direction: column;
  }
}

.spin {
  animation: spin 0.8s linear infinite;
}

/* ProgressBar.css */
.progress-bar-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.progress-container {
  display: flex;
  align-items: center;
  width: 400px;
  margin-bottom: 10px;
}

.progress-bar-container {
  flex-grow: 1;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background-color: #000;
  transition: width 0.5s ease-in-out; /* Transição suave */
  border-radius: 10px 0 0 10px;
}

.progress-bar.pulse {
  animation: pulse-animation 1.5s infinite ease-in-out;
}

@keyframes pulse-animation {
  0% {
    transform: scale(1);
    background-color: #000;
  }
  50% {
    transform: scale(1.05);
    background-color: #555;
  }
  100% {
    transform: scale(1);
    background-color: #000;
  }
}

.progress-percentage {
  font-size: 16px;
  font-weight: 600;
  min-width: 50px; /* Largura mínima para o texto */
  text-align: right;
  margin-left: 10px; /* Espaço à esquerda da barra de progresso */
}

.progress-message {
  font-size: 14px;
  font-weight: 400;
  color: #333;
  margin-top: 10px; /* Espaço acima da mensagem */
  text-align: center; /* Centraliza a mensagem */
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.assistants-menu-mobile {
  cursor: pointer;

  &:hover {
    background: $gray100;
  }
}

// globals
.span {
  color: $gray900 !important;
  font-family: "Inter", sans-serif;
  font-size: 14px !important;
  font-weight: 500;
  line-height: 24px !important;
}

//
.select-avatar {
  width: 100%;
  max-width: 400px;
  border-radius: 8px;

  @media (max-width: 568px) {
    max-width: 220px;
  }
}

.select-antd-create-assistant {
  width: 100%;
  max-width: 400px;
  border: 1px solid #d0d5dd;
  border-radius: 8px;

  @media (max-width: 568px) {
    max-width: 220px;
  }
}

//
.ant-select-item-group {
  display: flex !important;
  justify-content: center;
}

// rotate arrow icon
.rotate-arrow {
  transition: transform 0.2s;
  transform: rotate(180deg);
}

.scrollable-container {
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none !important;
  }

  .history-payments {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: $gray200;
    }
  }
}

// divider
.divider {
  border: 1px solid rgba($gray300, 0.6);
  height: 1px;
}

.ais-SearchBox-input::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.toast-no-creditis {
  background: $gray100 !important;

  button {
    background: #fff !important;
    padding: 15px 16px !important;
    color: $gray500 !important;

    &:hover {
      color: $gray900 !important;
    }
  }
}

.btn-manage-chat {
  color: $gray500;
  button {
    color: $gray500;
    &:hover {
      color: $gray700;
    }
  }
}

.select-language-antd {
  .ant-select-selection-item {
    padding: 0 !important;
  }

  .ant-select-selector {
    padding: 0 !important;
  }
  .ant-select-arrow {
    display: none !important;
  }
}

// background input auto
.autofill-fix:-webkit-autofill {
  box-shadow: 0 0 0 1000px $gray100 inset !important;
}

.faqs-collapse {
  background-color: transparent !important;
  border: none !important;
  max-width: 700px !important;
  width: 100%;

  @media (max-width: 991.98px) {
    max-width: 100% !important;
  }
  .ant-collapse-item {
    margin-bottom: 24px !important;
    background: #F9FAFB !important;
    border-bottom: none !important;
    border-radius: 24px !important;
    padding: 10px 16px !important;

    @media (max-width: 991.98px) {
     padding: 0 8px !important;
     border-radius: 16px !important;
    }

    .ant-collapse-header {
      align-items: center !important;

      .ant-collapse-header-text{
        font-size: 20px !important;
        color: $gray900 !important;

        @media (max-width: 991.98px) {
          font-size: 18px !important;
        }
      }
    }

    &.ant-collapse-item-active {
      background: $gray100 !important;
      border-bottom: none !important;
    }

    .ant-collapse-content{
      border-top:  none !important;
      border-radius: 0 0 24px 24px !important;
      background-color: transparent !important;
    }

    .ant-collapse-content-box {
      padding: 0 16px 16px 16px !important;
      border-radius: 0 0 24px 24px !important;

      p {
        font-size: 16px !important;
        color: $gray900 !important;

        @media (max-width: 991.98px) {
          font-size: 14px !important;
        }
      }
    }
  }
}
