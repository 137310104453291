.page-assistants {
  padding: 60px 20px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  flex-grow: 1;
  flex-basis: 0;
  max-width: calc(100%);
  position: relative;
  transition: all 0.3s;
  overflow: auto;

  @media (max-width: 768.98px) {
    overflow-x: hidden;
    height: calc(100vh - 80px);

    .tolltip-about-create-assistant {
      @media screen and (max-width: 568px) {
        .tooltip-box {
          left: -60px !important;
          .tooltip-arrow{
            left: 60px !important;
          }
        }
      }
    }
  }


  .tab-button {
    padding: 8px 10px;
    border-radius: $borderRadiusSm;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: $gray500;
    min-width: 50px;

    &:hover {
      color: $gray700;
    }

    &.active {
      background: $gray100 !important;
      color: $gray900;
    }

    &::-moz-placeholder {
      font-family: "Inter", sans-serif;
      color: $gray500;
    }
  }

  input {
    height: 52px;
    background: transparent;
    padding: 0;
    color: $gray900;
    outline: none;
    width: 100%;
    border: none;
    font-size: 14px !important;
  }

  @media (max-width: 991px) {
    max-width: 100%;
    margin-right: -270px;
  }
  @media (max-width: 768.98px) {
    max-width: 100%;
  }

  &.maximize {
    max-width: calc(100% - 70px);

    @media (max-width: 992px) {
      max-width: calc(100% - 50px);
    }
    @media (max-width: 768.98px) {
      max-width: calc(100%);
    }
  }

  h2 {
    margin: 0;
    font-family: "Inter", sans-serif;
    font-size: 28px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: -0.02em;
    text-align: center;
    color: $gray900;
  }
  h3 {
    margin: 0;
    font-family: "Inter", sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.01em;
    text-align: left;
    color: $gray900;
  }

  span {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.01em;
    text-align: left;
    color: $gray500;
  }

  .button-footer-grid-asssistants {
    background: $gray100;
    height: 40px;
    border-radius: 10px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: $gray900;
  }

  .grid-horizontals-assistants {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    @media (max-width: 767px) {
      grid-template-columns: 1fr;
      width: 100%;
    }
  }
  .grid-vertical-assistants {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
    @media (max-width: 767px) {
      grid-template-columns: 1fr 1fr;
      width: 100%;
    }
  }

  // modal create assistants

  .modal-create-assistants {
    width: 700px;
    padding: 24px;
    gap: 32px;
    height: calc(100% - 80px);

    @media (max-width: 568px) {
      gap: 16px;
      height: 100%;
    }

    .ant-select-selector {
      padding: 0 16px !important;
    }

    .ant-select-selection-search {
      input {
        border: none !important;
      }
    }

    .stepper-bar {
      border-top: 4px solid $gray200;

      &.active {
        border-top: 4px solid $gray900;
      }
    }
    .stepper-label {
      color: $gray400;
      font-family: "Inter", sans-serif;
      font-size: 14px;

      &.active {
        color: $gray900;
      }
    }
  }

  // content general nifo

  .create-assistants-general-info {
    display: flex;
    flex-direction: column;
    gap: 20px;

    > div {
      .ant-select-selection-search {
        inset-inline-start: 0 !important;
        inset-inline-end: 0 !important;
      }
    }

    input {
      width: 100%;
      height: 40px;
      border-radius: 8px;
      padding: 0 16px;
      border: 1px solid $gray300 !important;
    }

    .div-input-general-info {
      width: 100%;
      max-width: 400px;

      input::placeholder {
        color: $gray500 !important;
      }

      @media (max-width: 568px) {
        max-width: 220px;
      }
    }

    .div-input-upload-avatar {
      border-radius: 8px;
      border: 1px solid $gray300 !important;
      padding: 0 16px;

      input {
        border: none !important;
        padding: 0 !important;
      }
    }
    
    // .upload-img{
    //   width: 100px !important;
    //   border: 1px solid $gray300 !important;
    //   gap: 16px;

    //   .kFhUBM{
    //     display: none !important;
    //   }
    // }
  }

  .ant-checkbox-input {
    height: 16px !important;
    padding: 0;
  }

  // content data
  .create-assistants-data {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .textarea-data {
      width: 100%;
      max-width: 400px;
      @media (max-width: 568px) {
        max-width: 220px;
      }

      textarea {
        padding: 12px 35px 12px 16px;
        border-radius: 8px;
        height: 110px;
        border: 1px solid $gray300 !important;
        width: 100%;
        font-size: 14px !important;
        font-family: "Inter", sans-serif;

        &:focus {
          outline: none;
        }
      }
    }
  }

  // content review create assistants
  .review-publish-assistants {
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 20px;

    .create-assistants-general-info {
      min-height: auto;
    }

    .create-assistants-data {
      min-height: auto;
    }
    input {
      width: 100%;
      height: 40px;
      border-radius: 8px;
      padding: 0 16px;
      border: 1px solid $gray300 !important;
    }
    .div-input-general-info {
      width: 100%;
      max-width: 400px;

      @media (max-width: 568px) {
        max-width: 220px;
      }
    }

    .textarea-data {
      textarea {
        padding: 12px 16px;
      }
    }
  }

  // modal
  .tooltip-box {
    left: -15px;
    transform: translateX(10px) translateY(-10px);

    @media (max-width: 568px) {
      left: -100px;
    }

    .tooltip-arrow {
      left: 15px;

      @media (max-width: 568px) {
        left: 100px;
      }
    }
  }
}
